<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box d-flex align-items-center justify-content-between">
                            <h4 class="mb-0 font-size-18">{{ clientName() }} - Settings</h4>

                            <div class="page-title-right">
                                <breadcrumbs :items="breadcrumbs"></breadcrumbs>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <div v-if="loading"><loading></loading></div>
                
                <div v-if="(!errors && !loading)" class="row mt-2">
                    <div class="col-xl-3 col-lg-4 col-md-6">
                        <div class="card">
                            <h5 class="card-header bg-primary text-white">System &amp; company</h5>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Company name
                                        </div>
                                        <div class="col-4">
                                            <div class="contact-links font-size-18 text-right">
                                                <a
                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#edit-content-modal"
                                                    @click.prevent="editContent('Company name', 'company_name')"
                                                >
                                                    <span v-b-tooltip.hover title="Edit">
                                                        <i class="far fa-edit"></i>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Terms of use and privacy policy content
                                        </div>
                                        <div class="col-4">
                                            <div class="contact-links font-size-18 text-right">
                                                <a
                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#edit-content-modal"
                                                    @click.prevent="editContent(
                                                        'Terms of use and privacy policy content',
                                                        'terms_and_conditions', true)"
                                                >
                                                    <span v-b-tooltip.hover.left title="Edit">
                                                        <i class="far fa-edit"></i>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Show Support in main navigation
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.show_support_link"
                                                    @click="updateSetting('show_support_link', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('show_support_link', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="card">
                            <h5 class="card-header bg-primary text-white">Homepage</h5>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Show welcome panel
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.show_welcome_panel"
                                                    @click="updateSetting('show_welcome_panel', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('show_welcome_panel', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-if="settings.show_welcome_panel"
                                        class="row no-gutters"
                                        style="margin-top: 5px"
                                    >
                                        <div class="col-8 pt-1 pb-1">
                                            Welcome panel colour
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    :style="select_colour_button_style"
                                                >
                                                    {{ optionText(getColourOptions(), settings.welcome_panel_colour) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div
                                                    class="dropdown-menu dropdown-menu-right"
                                                    style="max-height: 250px; overflow-y: auto"
                                                >
                                                    <a
                                                        v-for="option in getColourOptions()"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption('welcome_panel_colour', option.key)"
                                                    >
                                                        <span
                                                            class="mr-2"
                                                            :style="select_colour_option_style + option.colour"
                                                        ></span>
                                                        <span style="position: relative; top: -2px">
                                                            {{ option.value }}
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-if="settings.show_welcome_panel"
                                        class="row no-gutters"
                                        style="margin-top: 5px"
                                    >
                                        <div class="col-8 pt-1 pb-1">
                                            Welcome panel button colour
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    :style="select_colour_button_style"
                                                >
                                                    {{ optionText(
                                                        getColourOptions(), settings.welcome_panel_button_colour) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div
                                                    class="dropdown-menu dropdown-menu-right"
                                                    style="max-height: 250px; overflow-y: auto"
                                                >
                                                    <a
                                                        v-for="option in getColourOptions()"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption(
                                                            'welcome_panel_button_colour', option.key)"
                                                    >
                                                        <span
                                                            class="mr-2"
                                                            :style="select_colour_option_style + option.colour"
                                                        ></span>
                                                        <span style="position: relative; top: -2px">
                                                            {{ option.value }}
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Flyte panel header colour
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    :style="select_colour_button_style"
                                                >
                                                    {{ optionText(getColourOptions(), settings.flyte_panel_header_colour) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div
                                                    class="dropdown-menu dropdown-menu-right"
                                                    style="max-height: 250px; overflow-y: auto"
                                                >
                                                    <a
                                                        v-for="option in getColourOptions()"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption('flyte_panel_header_colour', option.key)"
                                                    >
                                                        <span
                                                            class="mr-2"
                                                            :style="select_colour_option_style + option.colour"
                                                        ></span>
                                                        <span style="position: relative; top: -2px">
                                                            {{ option.value }}
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row no-gutters" style="margin-top: 5px">
                                        <div class="col-8 pt-1 pb-1">
                                            Flyte panel body colour
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    :style="select_colour_button_style"
                                                >
                                                    {{ optionText(getColourOptions(), settings.flyte_panel_colour) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div
                                                    class="dropdown-menu dropdown-menu-right"
                                                    style="max-height: 250px; overflow-y: auto"
                                                >
                                                    <a
                                                        v-for="option in getColourOptions()"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption('flyte_panel_colour', option.key)"
                                                    >
                                                        <span
                                                            class="mr-2"
                                                            :style="select_colour_option_style + option.colour"
                                                        ></span>
                                                        <span style="position: relative; top: -2px">
                                                            {{ option.value }}
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row no-gutters" style="margin-top: 5px">
                                        <div class="col-8 pt-1 pb-1">
                                            Flyte panel button colour
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    :style="select_colour_button_style"
                                                >
                                                    {{ optionText(getColourOptions(), settings.flyte_panel_button_colour) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div
                                                    class="dropdown-menu dropdown-menu-right"
                                                    style="max-height: 250px; overflow-y: auto"
                                                >
                                                    <a
                                                        v-for="option in getColourOptions()"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption('flyte_panel_button_colour', option.key)"
                                                    >
                                                        <span
                                                            class="mr-2"
                                                            :style="select_colour_option_style + option.colour"
                                                        ></span>
                                                        <span style="position: relative; top: -2px">
                                                            {{ option.value }}
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="card">
                            <h5 class="card-header bg-primary text-white">Notifications</h5>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Number of notifications to load at once
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ optionText(per_page_options, settings.notifications_per_page) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <a
                                                        v-for="option in per_page_options"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption('notifications_per_page', option.key)"
                                                    >{{ option.value }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="card">
                            <h5 class="card-header bg-primary text-white">Reports</h5>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Number of report items to load at once
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ optionText(per_page_options, settings.reports_per_page) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <a
                                                        v-for="option in per_page_options"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption('reports_per_page', option.key)"
                                                    >{{ option.value }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="card">
                            <h5 class="card-header bg-primary text-white">Print ordering</h5>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Financial approval system
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.print_ordering_approval"
                                                    @click="updateSetting('print_ordering_approval', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('print_ordering_approval', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li v-if="settings.print_ordering_approval" class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Send order to copy recipients before approval
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.unapproved_orders_copy_recipients"
                                                    @click="updateSetting('unapproved_orders_copy_recipients', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting(
                                                            'unapproved_orders_copy_recipients', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li v-if="settings.print_ordering_approval" class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Send remider to approver
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ optionText(print_approver_reminder_days_options,
                                                        settings.print_approver_reminder_days) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <a
                                                        v-for="option in print_approver_reminder_days_options"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption('print_approver_reminder_days',
                                                            option.key)"
                                                    >{{ option.value }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Number of orders to load at once
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ optionText(per_page_options, settings.orders_per_page) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <a
                                                        v-for="option in per_page_options"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption('orders_per_page', option.key)"
                                                    >{{ option.value }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Order form text above price panel drop down
                                        </div>
                                        <div class="col-4">
                                            <div class="contact-links font-size-18 text-right">
                                                <a
                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#edit-content-modal"
                                                    @click.prevent="editContent(
                                                        'Order form text above price panel drop down',
                                                        'select_price_panel_text')"
                                                >
                                                    <span v-b-tooltip.hover title="Edit">
                                                        <i class="far fa-edit"></i>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6">
                        <div class="card">
                            <h5 class="card-header bg-primary text-white">Login &amp; registration</h5>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Login panel position
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ optionText(login_panel_position_options, settings.login_panel_position) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <a
                                                        v-for="option in login_panel_position_options"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption('login_panel_position', option.key)"
                                                    >{{ option.value }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Show "Remember me" on login panel
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.allow_remember_me"
                                                    @click="updateSetting('allow_remember_me', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('allow_remember_me', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Allow new registrations
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.allow_register"
                                                    @click="updateSetting('allow_register', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('allow_register', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li v-if="settings.allow_register" class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Registration form introduction/instruction
                                        </div>
                                        <div class="col-4">
                                            <div class="contact-links font-size-18 text-right">
                                                <a
                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#edit-content-modal"
                                                    @click.prevent="editContent(
                                                        'Registration form introduction/instruction',
                                                        'registration_info', true)"
                                                >
                                                    <span v-b-tooltip.hover.left title="Edit">
                                                        <i class="far fa-edit"></i>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li v-if="settings.allow_register" class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Additional fields for non-staff applicants
                                            <a
                                                class="font-size-15 ml-1"
                                                href="#"
                                                v-b-tooltip.hover
                                                :title="'To facilitate Admin approval, require non-staff applicants '
                                                    + 'to provide the name, email and telephone number for their '
                                                    + 'staff contact.'"
                                                @click.prevent="doNothing()"
                                            >
                                                <i class="far fa-info-circle"></i>
                                            </a>
                                        </div>
                                        <div class="col-4">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.company_contact_details"
                                                    @click="updateSetting('company_contact_details', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('company_contact_details', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="settings.company_contact_details" class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Additional fields introduction/instruction
                                        </div>
                                        <div class="col-4">
                                            <div class="contact-links font-size-18 text-right">
                                                <a
                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#edit-content-modal"
                                                    @click.prevent="editContent(
                                                        'Additional fields introduction/instruction',
                                                        'company_contact_name_label', true)"
                                                >
                                                    <span v-b-tooltip.hover.left title="Edit">
                                                        <i class="far fa-edit"></i>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li v-if="settings.allow_register" class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Show confirm checkbox
                                        </div>
                                        <div class="col-4">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.registration_confirm"
                                                    @click="updateSetting('registration_confirm', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('registration_confirm', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="settings.registration_confirm" class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Checkbox position
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ optionText(registration_confirm_position_options,
                                                        settings.registration_confirm_position) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <a
                                                        v-for="option in registration_confirm_position_options"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption('registration_confirm_position',
                                                            option.key)"
                                                    >{{ option.value }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-if="settings.registration_confirm"
                                        class="row no-gutters"
                                        style="margin-top: 5px"
                                    >
                                        <div class="col-8 pt-1 pb-1">
                                            Confirmation message
                                        </div>
                                        <div class="col-4">
                                            <div class="contact-links font-size-18 text-right">
                                                <a
                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#edit-content-modal"
                                                    @click.prevent="editContent('Confirmation message',
                                                        'registration_confirm_text', false, true)"
                                                >
                                                    <span v-b-tooltip.hover.left title="Edit">
                                                        <i class="far fa-edit"></i>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Must assent to terms of use and privacy policy
                                        </div>
                                        <div class="col-4">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.must_accept_terms"
                                                    @click="updateSetting('must_accept_terms', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('must_accept_terms', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            User password expires in
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ optionText(password_expiry_days_options,
                                                        settings.password_expiry_days) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <a
                                                        v-for="option in password_expiry_days_options"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption('password_expiry_days', option.key)"
                                                    >{{ option.value }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Fisrt name and last name not required to reset password
                                        </div>
                                        <div class="col-4">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.reset_password_email_only"
                                                    @click="updateSetting('reset_password_email_only', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('reset_password_email_only',
                                                            false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="card">
                            <h5 class="card-header bg-primary text-white">Manage users</h5>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Number of users to load at once
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ optionText(per_page_options, settings.users_per_page) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <a
                                                        v-for="option in per_page_options"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption('users_per_page', option.key)"
                                                    >{{ option.value }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            User invite link expires in
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ optionText(invite_expiry_days_options, settings.invite_expiry_days) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <a
                                                        v-for="option in invite_expiry_days_options"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption('invite_expiry_days', option.key)"
                                                    >{{ option.value }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="card">
                            <h5 class="card-header bg-primary text-white">Sharing</h5>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Show 3rd party platform option in Get a link
                                        </div>
                                        <div class="col-4">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.share_link_3rd_party"
                                                    @click="updateSetting('share_link_3rd_party', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('share_link_3rd_party', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="settings.share_link_3rd_party" class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            3rd party platform name
                                        </div>
                                        <div class="col-4">
                                            <div class="contact-links font-size-18 text-right">
                                                <a
                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#edit-content-modal"
                                                    @click.prevent="editContent('3rd party platform name',
                                                        'share_link_3rd_party_platform', false, true)"
                                                >
                                                    <span v-b-tooltip.hover.left title="Edit">
                                                        <i class="far fa-edit"></i>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="card">
                            <h5 class="card-header bg-primary text-white">POD codes</h5>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Number of POD codes to load at once
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ optionText(per_page_options, settings.pod_codes_per_page) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <a
                                                        v-for="option in per_page_options"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption('pod_codes_per_page', option.key)"
                                                    >{{ option.value }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="card">
                            <h5 class="card-header bg-primary text-white">Collections</h5>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Search collaborator users by full email
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.collaborator_search_by_full_email"
                                                    @click="updateSetting('collaborator_search_by_full_email', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('collaborator_search_by_full_email',
                                                            false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6">
                        <div class="card">
                            <h5 class="card-header bg-primary text-white">{{ $root.ucfirst(contents.asset_title) }}s</h5>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Term for "resource"
                                        </div>
                                        <div class="col-4">
                                            <div class="contact-links font-size-18 text-right">
                                                <a
                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#edit-content-modal"
                                                    @click.prevent="editContent('Term for &quot;resource&quot;',
                                                        'asset_title', false, true)"
                                                >
                                                    <span v-b-tooltip.hover.left title="Edit">
                                                        <i class="far fa-edit"></i>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Flexible search from keyword length
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ optionText(flex_search_char_options, settings.asset_flex_search_chars) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <a
                                                        v-for="option in flex_search_char_options"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption('asset_flex_search_chars', option.key)"
                                                    >{{ option.value }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Auto-complete search
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.asset_search_autocomplete"
                                                    @click="updateSetting('asset_search_autocomplete', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('asset_search_autocomplete', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    AI keywords
                                    <div class="row no-gutters" style="margin-top: 10px">
                                        <div class="col-8 pt-1 pb-1">
                                            Maximum number of keywords
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ optionText(detect_labels_limit_options, settings.assets_detect_labels_limit) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <a
                                                        v-for="option in detect_labels_limit_options"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption('assets_detect_labels_limit', option.key)"
                                                    >{{ option.value }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row no-gutters" style="margin-top: 5px">
                                        <div class="col-8 pt-1 pb-1">
                                            Minimum percentage match
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ optionText(detect_labels_confidence_options, settings.assets_detect_labels_confidence) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <a
                                                        v-for="option in detect_labels_confidence_options"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption('assets_detect_labels_confidence', option.key)"
                                                    >{{ option.value }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row no-gutters" style="margin-top: 5px">
                                        <div class="col-8 pt-1 pb-1">
                                            Use in search, {{ contents.asset_title }} record and upload
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.assets_use_detected_labels"
                                                    @click="updateSetting('assets_use_detected_labels', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('assets_use_detected_labels',
                                                            false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Number of {{ contents.asset_title }}s to load at once
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ optionText(per_page_options, settings.assets_per_page) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <a
                                                        v-for="option in per_page_options"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption('assets_per_page', option.key)"
                                                    >{{ option.value }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            {{ $root.ucfirst(contents.asset_title) }} details default view
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ optionText(asset_details_default_tab_options, settings.asset_details_default_tab) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <a
                                                        v-for="option in asset_details_default_tab_options"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption('asset_details_default_tab', option.key)"
                                                    >{{ option.value }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Show {{ contents.asset_title }} "Description"
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.show_asset_description"
                                                    @click="updateSetting('show_asset_description', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('show_asset_description', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="settings.show_asset_description" class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Required field
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.asset_description_required"
                                                    @click="updateSetting('asset_description_required', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('asset_description_required', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="settings.show_asset_description" class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Field title (term for "description")
                                        </div>
                                        <div class="col-4">
                                            <div class="contact-links font-size-18 text-right">
                                                <a
                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#edit-content-modal"
                                                    @click.prevent="editContent('Term for &quot;description&quot;',
                                                        'description_title', false, true)"
                                                >
                                                    <span v-b-tooltip.hover.left title="Edit">
                                                        <i class="far fa-edit"></i>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Watermark {{ contents.asset_title }}s that require download approval
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.asset_watermarking"
                                                    @click="updateSetting('asset_watermarking', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('asset_watermarking', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    Add to {{ contents.asset_title }} download filename...
                                    <div class="row no-gutters" style="margin-top: 10px">
                                        <div class="col-8 pt-1 pb-1">
                                            "rb_" prefix
                                        </div>
                                        <div class="col-4">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.asset_download_prefix"
                                                    @click="updateSetting('asset_download_prefix', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('asset_download_prefix', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            {{ $root.ucfirst(contents.asset_title) }} title
                                        </div>
                                        <div class="col-4">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.asset_download_title"
                                                    @click="updateSetting('asset_download_title', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('asset_download_title', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Original filename
                                        </div>
                                        <div class="col-4">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.asset_download_filename"
                                                    @click="updateSetting('asset_download_filename', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('asset_download_filename', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Ask for briefing details when requesting approval
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.asset_approval_usage_notes"
                                                    @click="updateSetting('asset_approval_usage_notes', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('asset_approval_usage_notes', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            {{ $root.ucfirst(contents.asset_title) }} approval expires in
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ optionText(invite_expiry_days_options, settings.asset_approval_expiry_days) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <a
                                                        v-for="option in invite_expiry_days_options"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption('asset_approval_expiry_days',
                                                            option.key)"
                                                    >{{ option.value }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Hide empty folders and subfolders
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.hide_empty_folders"
                                                    @click="updateSetting('hide_empty_folders', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('hide_empty_folders', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Invert folder colours in mega menu
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.invert_search_dropdown_folder_colours"
                                                    @click="updateSetting('invert_search_dropdown_folder_colours', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting(
                                                            'invert_search_dropdown_folder_colours', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Populate title field on upload 
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.asset_upload_populate_title"
                                                    @click="updateSetting('asset_upload_populate_title', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('asset_upload_populate_title',
                                                            false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            User groups required (need to select at least one or "All users" on the
                                            upload forms)
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.asset_user_group_required"
                                                    @click="updateSetting('asset_user_group_required', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('asset_user_group_required',
                                                            false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="card">
                            <h5 class="card-header bg-primary text-white">
                                {{ $root.ucfirst(contents.template_title) }}s
                            </h5>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Term for "template"
                                        </div>
                                        <div class="col-4">
                                            <div class="contact-links font-size-18 text-right">
                                                <a
                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#edit-content-modal"
                                                    @click.prevent="editContent('Term for &quot;template&quot;',
                                                        'template_title', false, true)"
                                                >
                                                    <span v-b-tooltip.hover.left title="Edit">
                                                        <i class="far fa-edit"></i>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            {{ $root.ucfirst(contents.template_title) }} details default view
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ optionText(template_details_default_tab_options, settings.template_details_default_tab) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <a
                                                        v-for="option in template_details_default_tab_options"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption('template_details_default_tab', option.key)"
                                                    >{{ option.value }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Number of artwork approvals to load at once
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ optionText(per_page_options, settings.artwork_approvals_per_page) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <a
                                                        v-for="option in per_page_options"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption('artwork_approvals_per_page',
                                                            option.key)"
                                                    >{{ option.value }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Compress artwork PDF files
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.artwork_downsample"
                                                    @click="updateSetting('artwork_downsample', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting(
                                                            'artwork_downsample', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    Additional {{ contents.template_title }} field TinyMCE tools...
                                    <div class="row no-gutters" style="margin-top: 10px">
                                        <div class="col-8 pt-1 pb-1">
                                            Font size
                                        </div>
                                        <div class="col-4">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.template_field_tool_fontsize"
                                                    @click="updateSetting('template_field_tool_fontsize', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('template_field_tool_fontsize',
                                                            false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Text alignment
                                        </div>
                                        <div class="col-4">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.template_field_tool_align"
                                                    @click="updateSetting('template_field_tool_align', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('template_field_tool_align',
                                                            false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Use "_QR", "_LQR" and "_LQRx" fields to generate QR codes
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.artwork_generate_qr_codes"
                                                    @click="updateSetting('artwork_generate_qr_codes', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting(
                                                            'artwork_generate_qr_codes', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6">
                        <div class="card">
                            <h5 class="card-header bg-primary text-white">
                                {{ $root.ucfirst(contents.profiles_title) }}
                            </h5>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Term for "profile group"
                                        </div>
                                        <div class="col-4">
                                            <div class="contact-links font-size-18 text-right">
                                                <a
                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#edit-content-modal"
                                                    @click.prevent="editContent('Term for &quot;profile group&quot;',
                                                        'profile_group_title', false, true)"
                                                >
                                                    <span v-b-tooltip.hover.left title="Edit">
                                                        <i class="far fa-edit"></i>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Plural "profile groups"
                                        </div>
                                        <div class="col-4">
                                            <div class="contact-links font-size-18 text-right">
                                                <a
                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#edit-content-modal"
                                                    @click.prevent="editContent('Plural &quot;profile groups&quot;',
                                                        'profile_groups_title', false, true)"
                                                >
                                                    <span v-b-tooltip.hover.left title="Edit">
                                                        <i class="far fa-edit"></i>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <!--<li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Term for "profile"
                                        </div>
                                        <div class="col-4">
                                            <div class="contact-links font-size-18 text-right">
                                                <a
                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#edit-content-modal"
                                                    @click.prevent="editContent('Term for &quot;profile&quot;',
                                                        'profile_title', false, true)"
                                                >
                                                    <span v-b-tooltip.hover.left title="Edit">
                                                        <i class="far fa-edit"></i>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Plural "profiles"
                                        </div>
                                        <div class="col-4">
                                            <div class="contact-links font-size-18 text-right">
                                                <a
                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#edit-content-modal"
                                                    @click.prevent="editContent('Plural &quot;profiles&quot;',
                                                        'profiles_title', false, true)"
                                                >
                                                    <span v-b-tooltip.hover.left title="Edit">
                                                        <i class="far fa-edit"></i>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </li>-->
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Number of {{ contents.profile_groups_title }}/{{ contents.profiles_title }}
                                            to load at once
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ optionText(per_page_options, settings.profiles_per_page) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <a
                                                        v-for="option in per_page_options"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption('profiles_per_page', option.key)"
                                                    >{{ option.value }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-12 pb-1">
                                            {{ $root.ucfirst(contents.profile_groups_title) }} folder
                                        </div>
                                        <div class="col-12">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ folderName(settings.profile_groups_folder) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div
                                                    class="dropdown-menu dropdown-menu-right"
                                                    style="max-height: 250px; overflow-y: auto"
                                                >
                                                    <a
                                                        v-for="folder in folders"
                                                        class="dropdown-item"
                                                        :key="folder.id"
                                                        href="#"
                                                        @click.prevent="setOption('profile_groups_folder', folder.id)"
                                                    >
                                                        {{ folder.name }}
                                                        <span v-if="folder.archived">(archived)</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Show {{ contents.profile_group_title }} "Site"
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.show_profile_group_description"
                                                    @click="updateSetting('show_profile_group_description', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('show_profile_group_description',
                                                            false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="settings.show_profile_group_description" class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Required field
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.profile_group_description_required"
                                                    @click="updateSetting('profile_group_description_required', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting(
                                                            'profile_group_description_required', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="settings.show_profile_group_description" class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Field title (term for "site")
                                        </div>
                                        <div class="col-4">
                                            <div class="contact-links font-size-18 text-right">
                                                <a
                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#edit-content-modal"
                                                    @click.prevent="editContent('Term for &quot;site&quot;',
                                                        'profile_group_description_title', false, true)"
                                                >
                                                    <span v-b-tooltip.hover.left title="Edit">
                                                        <i class="far fa-edit"></i>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Show {{ contents.profile_group_title }} "Subfolder"
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.show_profile_group_subfolder_id"
                                                    @click="updateSetting('show_profile_group_subfolder_id', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('show_profile_group_subfolder_id',
                                                            false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="settings.show_profile_group_subfolder_id" class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Required field
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.profile_group_subfolder_id_required"
                                                    @click="updateSetting('profile_group_subfolder_id_required', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting(
                                                            'profile_group_subfolder_id_required', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Show {{ contents.profile_group_title }} "Account number"
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.show_profile_group_account_number"
                                                    @click="updateSetting('show_profile_group_account_number', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting(
                                                            'show_profile_group_account_number', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="settings.show_profile_group_account_number" class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Required field
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.profile_group_account_number_required"
                                                    @click="updateSetting('profile_group_account_number_required', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting(
                                                            'profile_group_account_number_required', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Show {{ contents.profile_group_title }} "Consultant"
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.show_profile_group_consultant"
                                                    @click="updateSetting('show_profile_group_consultant', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('show_profile_group_consultant',
                                                            false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="settings.show_profile_group_consultant" class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Required field
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.profile_group_consultant_required"
                                                    @click="updateSetting('profile_group_consultant_required', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting(
                                                            'profile_group_consultant_required', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li v-if="settings.print_ordering_approval" class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Financial approver field title (term for "financial approver")
                                        </div>
                                        <div class="col-4">
                                            <div class="contact-links font-size-18 text-right">
                                                <a
                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#edit-content-modal"
                                                    @click.prevent="editContent(
                                                        'Term for &quot;financial approver&quot;',
                                                        'financial_approver_title', false, true)"
                                                >
                                                    <span v-b-tooltip.hover.left title="Edit">
                                                        <i class="far fa-edit"></i>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li v-if="client" class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-6 pt-1 pb-1">
                                            Default role for {{ contents.profile_group_title }} users
                                        </div>
                                        <div class="col-6">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ roleName(settings.profile_group_default_role) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div
                                                    class="dropdown-menu dropdown-menu-right"
                                                    style="max-height: 250px; overflow-y: auto"
                                                >
                                                    <a
                                                        v-for="role in client.client_roles"
                                                        class="dropdown-item"
                                                        :key="role.id"
                                                        href="#"
                                                        @click.prevent="setOption('profile_group_default_role', role.id)"
                                                    >
                                                        {{ role.name }}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Default currency for {{ contents.profile_group_title }} users
                                        </div>
                                        <div class="col-4">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ optionText(currency_options,
                                                        settings.profile_group_default_currency) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div
                                                    class="dropdown-menu dropdown-menu-right"
                                                    style="max-height: 250px; overflow-y: auto"
                                                >
                                                    <a
                                                        v-for="option in currency_options"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption('profile_group_default_currency',
                                                            option.key)"
                                                    >
                                                        {{ option.value }}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="card">
                            <h5 class="card-header bg-primary text-white">Proof tracker</h5>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Number of proofs to load at once
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ optionText(per_page_options, settings.proofs_per_page) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <a
                                                        v-for="option in per_page_options"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption('proofs_per_page', option.key)"
                                                    >{{ option.value }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Linked {{ contents.asset_title }} function
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.proof_link_assets"
                                                    @click="updateSetting('proof_link_assets', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('proof_link_assets', false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Ask for reason when skipping a proof reviewer
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="contact-links font-size-22 text-right">
                                                <span
                                                    v-if="!settings.proof_skip_reviewer_reason"
                                                    @click="updateSetting('proof_skip_reviewer_reason', true)"
                                                >
                                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                                </span>
                                                <span v-else>
                                                    <a
                                                        href="#"
                                                        @click.prevent="updateSetting('proof_skip_reviewer_reason',
                                                            false)"
                                                    >
                                                        <i class="far fa-toggle-on"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-8 pt-1 pb-1">
                                            Auto-archive completed proofs
                                        </div>
                                        <div class="col-4 text-right">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ optionText(auto_archive_proof_weeks_options,
                                                        settings.auto_archive_proof_weeks) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <a
                                                        v-for="option in auto_archive_proof_weeks_options"
                                                        class="dropdown-item"
                                                        :key="option.key"
                                                        href="#"
                                                        @click.prevent="setOption('auto_archive_proof_weeks',
                                                            option.key)"
                                                    >{{ option.value }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li v-if="client" class="list-group-item">
                                    <div class="row no-gutters">
                                        <div class="col-6 pt-1 pb-1">
                                            Guest user role
                                        </div>
                                        <div class="col-6">
                                            <div class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ roleName(settings.proofs_guest_role) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div
                                                    class="dropdown-menu dropdown-menu-right"
                                                    style="max-height: 250px; overflow-y: auto"
                                                >
                                                    <a
                                                        v-for="role in client.client_roles"
                                                        class="dropdown-item"
                                                        :key="role.id"
                                                        href="#"
                                                        @click.prevent="setOption('proofs_guest_role', role.id)"
                                                    >
                                                        {{ role.name }}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <site-footer></site-footer>

        <div
            v-if="client"
            class="modal fade"
            id="edit-content-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="edit-content-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-lg">
                <edit-content :client="client" :admin_key="admin_key"></edit-content>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                breadcrumbs: [
                    {
                        id: 0,
                        text: "Clients",
                        active: true,
                        link: 'clients'
                    },
                    {
                        id: 1,
                        text: "Client settings"
                    }
                ],
                loading: false,
                errors: false,
                client: null,
                admin_key: null,
                contents: {
                    asset_title: 'resource',
                    template_title: 'template',
                    profile_group_title: 'profile group',
                    profile_groups_title: 'profile groups',
                    profiles_title: 'profiles',
                    select_price_panel_text: '',
                    registration_confirm_text: ''
                },
                settings: {
                    show_welcome_panel: false,
                    welcome_panel_colour: 'third',
                    welcome_panel_button_colour: 'primary',
                    flyte_panel_colour: 'third',
                    flyte_panel_header_colour: 'third',
                    flyte_panel_button_colour: 'primary',
                    login_panel_position: 'center',
                    allow_remember_me: true,
                    allow_register: true,
                    company_contact_details: false,
                    must_accept_terms: false,
                    password_expiry_days: 366,
                    asset_flex_search_chars: 5,
                    assets_per_page: 25,
                    asset_download_prefix: true,
                    asset_download_title: true,
                    asset_download_filename: true,
                    users_per_page: 25,
                    invite_expiry_days: 3,
                    show_asset_description: false,
                    asset_description_required: true,
                    asset_watermarking: true,
                    asset_approval_expiry_days: 7,
                    asset_approval_usage_notes: false,
                    notifications_per_page: 25,
                    reports_per_page: 25,
                    hide_empty_folders: true,
                    invert_search_dropdown_folder_colours: false,
                    show_support_link: true,
                    asset_details_default_tab: 'download',
                    share_link_3rd_party: false,
                    pod_codes_per_page: 25,
                    profile_groups_folder: null,
                    profiles_per_page: 25,
                    print_ordering_approval: false,
                    orders_per_page: 25,
                    asset_user_group_required: false,
                    artwork_approvals_per_page: 25,
                    unapproved_orders_copy_recipients: false,
                    show_profile_group_description: false,
                    profile_group_description_required: false,
                    show_profile_group_subfolder_id: false,
                    profile_group_subfolder_id_required: false,
                    show_profile_group_account_number: false,
                    profile_group_account_number_required: false,
                    show_profile_group_consultant: false,
                    profile_group_consultant_required: false,
                    template_details_default_tab: 'template-options',
                    collaborator_search_by_full_email: false,
                    print_approver_reminder_days: 0,
                    proofs_per_page: 25,
                    auto_archive_proof_weeks: 1,
                    proof_link_assets: false,
                    profile_group_default_role: null,
                    profile_group_default_currency: null,
                    registration_confirm: false,
                    registration_confirm_position: 'top',
                    proof_skip_reviewer_reason: false,
                    artwork_downsample: false,
                    asset_search_autocomplete: false,
                    proofs_guest_role: null,
                    reset_password_email_only: false,
                    asset_upload_populate_title: false,
                    template_field_tool_fontsize: false,
                    template_field_tool_align: false,
                    assets_use_detected_labels: false,
                    assets_detect_labels_limit: 20,
                    assets_detect_labels_confidence: 60,
                    artwork_generate_qr_codes: false
                },
                login_panel_position_options: [
                    {
                        key: 'left',
                        value: 'Left'
                    },
                    {
                        key: 'center',
                        value: 'Center'
                    },
                    {
                        key: 'right',
                        value: 'Right'
                    }
                ],
                password_expiry_days_options: [
                    {
                        key: 0,
                        value: 'Never'
                    },
                    {
                        key: 31,
                        value: '1 month'
                    },
                    {
                        key: 61,
                        value: '2 months'
                    },
                    {
                        key: 92,
                        value: '3 months'
                    },
                    {
                        key: 183,
                        value: '6 months'
                    },
                    {
                        key: 366,
                        value: '1 year'
                    }
                ],
                invite_expiry_days_options: [
                    {
                        key: 2,
                        value: '2 days'
                    },
                    {
                        key: 3,
                        value: '3 days'
                    },
                    {
                        key: 7,
                        value: '7 days'
                    },
                    {
                        key: 14,
                        value: '14 days'
                    }
                ],
                flex_search_char_options: [
                    {
                        key: 3,
                        value: '3 characters'
                    },
                    {
                        key: 4,
                        value: '4 characters'
                    },
                    {
                        key: 5,
                        value: '5 characters'
                    },
                    {
                        key: 6,
                        value: '6 characters'
                    },
                    {
                        key: 7,
                        value: '7 characters'
                    },
                    {
                        key: 8,
                        value: '8 characters'
                    },
                    {
                        key: 9,
                        value: '9 characters'
                    },
                    {
                        key: 10,
                        value: '10 characters'
                    }
                ],
                per_page_options: [
                    {
                        key: 25,
                        value: 25
                    },
                    {
                        key: 50,
                        value: 50
                    },
                    {
                        key: 75,
                        value: 75
                    },
                    {
                        key: 100,
                        value: 100
                    }
                ],
                asset_details_default_tab_options: [
                    {
                        key: 'download',
                        value: 'Downloads'
                    },
                    {
                        key: 'info',
                        value: 'Info'
                    }
                ],
                registration_confirm_position_options: [
                    {
                        key: 'top',
                        value: 'Top'
                    },
                    {
                        key: 'bottom',
                        value: 'Bottom'
                    }
                ],
                get_content_response: null,
                select_colour_button_style: 'text-align: left; box-shadow: 0 0.05rem 0.75rem rgba(18, 38, 63, 0.03) '
                    + '!important',
                select_colour_option_style: 'display: inline-block; width: 15px; height: 15px; '
                    + 'border: 1px solid #eff2f7; background-color: ',
                folders: [],
                template_details_default_tab_options: [
                    {
                        key: 'template-options',
                        value: 'Edit'
                    },
                    {
                        key: 'info',
                        value: 'Info'
                    }
                ],
                print_approver_reminder_days_options: [
                    {
                        key: 0,
                        value: 'Never'
                    },
                    {
                        key: 1,
                        value: 'After 1 day'
                    },
                    {
                        key: 2,
                        value: 'After 2 days'
                    },
                    {
                        key: 3,
                        value: 'After 3 days'
                    },
                    {
                        key: 5,
                        value: 'After 5 days'
                    },
                    {
                        key: 7,
                        value: 'After 7 days'
                    },
                    {
                        key: 14,
                        value: 'After 14 days'
                    }
                ],
                auto_archive_proof_weeks_options: [
                {
                        key: 0,
                        value: 'Immediately'
                    },
                    {
                        key: 1,
                        value: 'After 1 week'
                    },
                    {
                        key: 2,
                        value: 'After 2 weeks'
                    },
                    {
                        key: 3,
                        value: 'After 3 weeks'
                    },
                    {
                        key: 4,
                        value: 'After 4 weeks'
                    }
                ],
                currency_options: [
                    {
                        key: 'pound',
                        value: '£'
                    },
                    {
                        key: 'euro',
                        value: '€'
                    },
                    {
                        key: 'dollar',
                        value: '$'
                    }
                ],
                detect_labels_limit_options: [
                    {
                        key: 10,
                        value: 10
                    },
                    {
                        key: 20,
                        value: 20
                    },
                    {
                        key: 30,
                        value: 30
                    },
                    {
                        key: 40,
                        value: 40
                    },
                    {
                        key: 50,
                        value: 50
                    }
                ],
                detect_labels_confidence_options: [
                    {
                        key: 50,
                        value: '50%'
                    },
                    {
                        key: 60,
                        value: '60%'
                    },
                    {
                        key: 70,
                        value: '70%'
                    },
                    {
                        key: 80,
                        value: '80%'
                    },
                    {
                        key: 90,
                        value: '90%'
                    },
                    {
                        key: 100,
                        value: '100%'
                    }
                ]
            }
        },
        mounted () {
            this.loading = true;
            this.errors = false;
            this.getClient();
            this.$root.expandSideNav();
        },
        watch: {
            get_content_response: function (val) {
                if (val) {
                    if (val.status == 200) {
                        if (val.key == 'asset_title') {
                            this.contents.asset_title = val.content;
                        }
                        if (val.key == 'template_title') {
                            this.contents.template_title = val.content;
                        }
                        if (val.key == 'profile_group_title') {
                            this.contents.profile_group_title = val.content;
                        }
                        if (val.key == 'profile_groups_title') {
                            this.contents.profile_groups_title = val.content;
                        }
                        if (val.key == 'profiles_title') {
                            this.contents.profiles_title = val.content;
                        }
                    }
                }
            }
        },
        methods: {
            getClient: function () {
                if (!this.$route.params.client_id) {
                    this.errors = 'Missing client ID';

                    return null;
                }

                /**
                 * Get client from API
                 */
                const url = this.$root.app.api_url + '/client/' + this.$route.params.client_id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.client = response.data.client;
                    vm.getAdminKey();
                    vm.getContent(vm, 'asset_title');
                    vm.getContent(vm, 'template_title');
                    vm.getContent(vm, 'profile_group_title');
                    vm.getContent(vm, 'profile_groups_title');
                    vm.getContent(vm, 'profiles_title');
                })
                .catch(function (error) {
                    vm.loading = false;
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        }
                    }
                });
            },
            clientName: function () {
                if (!this.$route.params.client_id) {
                    return '';
                }
                if (this.client) {
                    return this.client.client_name;
                }

                return this.$route.params.client_id;
            },
            getAdminKey: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/admin-key';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.admin_key = response.data;
                    vm.getSettings();
                    vm.getFolders();
                })
                .catch(function (error) {
                    vm.loading = false;
                    vm.errors = 'Could not retrieve admin key';
                    if (error.response.data.error) {
                        vm.errors += '. ' + error.response.data.error;
                    }
                });
            },
            getSettings: function () {
                /**
                 * Get settings from API
                 */
                const path = '/' + this.client.id + '/settings/admin/' + this.admin_key;
                const url = this.$root.app.api_url + path;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    for (const [key, value] of Object.entries(response.data.settings)) {
                        vm.settings[key] = value;
                        if (!(key in vm.client)) {
                            vm.client[key] = value;
                        }
                    }
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        }
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            getFolders: function () {
                /**
                 * Get settings from API
                 */
                const path = '/' + this.client.id + '/folders/!pagination|!subfolders/admin/' + this.admin_key;
                const url = this.$root.app.api_url + path;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.folders = response.data.folders;
                })
                .catch(function (error) {
                    //
                });
            },
            updateSetting: function(key, value = null) {
                if (value !== null) {
                    this.settings[key] = value;
                }
                let updateValue = this.settings[key];
                if (updateValue === true) {
                    updateValue = 'true';
                }
                if (updateValue === false) {
                    updateValue = 'false';
                }

                /**
                 * Send update setting request to API
                 */
                var path = '/' + this.client.id + '/setting/' + key + '/admin/' + this.admin_key;
                var url = this.$root.app.api_url + path;
                var data = {
                    value: updateValue
                };
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // update client data
                    vm.client[key] = vm.settings[key];
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.status == 404) {
                        /**
                         * Setting does not exists - create new
                         * Send create setting request to API
                         */
                        path = '/' + vm.client.id + '/setting/admin/' + vm.admin_key;
                        url = vm.$root.app.api_url + path;
                        data = {
                            key: key,
                            value: updateValue
                        };

                        axios({
                            method: 'post',
                            url: url,
                            data: data,
                            headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                        })
                        .then(response => {
                            // update client data
                            vm.client[key] = vm.settings[key];
                        })
                        .catch(function (error) {
                            if (error.response.status == 401) {
                                // missing/lost API token - logout user
                                vm.$root.doLogout();
                            }
                            // reset setting if already exists in client
                            if (key in vm.client) {
                                vm.settings[key] = vm.client[key];
                            }
                            // show error
                            if (error.response.data.error) {
                                vm.errors = error.response.data.error;
                            } else {
                                vm.errors = 'Something went wrong, please try again';
                            }
                        });
                    } else {
                        // reset setting if already exists in frontend
                        if (key in vm.client) {
                            vm.settings[key] = vm.client[key];
                        }
                        // show error
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        } else {
                            vm.errors = 'Something went wrong, please try again';
                        }
                    }
                });
            },
            editContent: function (title, key, multiLine = false, mandatory = false) {
                const component = this.$root.findComponent(this, 'edit-content');
                if (component) {
                    component.resetForm(title, key, multiLine, mandatory);
                }
            },
            optionText: function (options, key) {
                let itemIndex = -1;
                options.forEach((item, index) => {
                    if (item.key == key) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    return options[itemIndex].value;
                }

                return 'Select';
            },
            folderName: function (folderId) {
                let itemIndex = -1;
                this.folders.forEach((item, index) => {
                    if (item.id == folderId) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    return this.folders[itemIndex].name + (this.folders[itemIndex].archived ? ' (archived)' : '');
                }

                return 'Select';
            },
            setOption: function (key, value) {
                this.settings[key] = value;
                this.updateSetting(key, value);
            },
            doNothing: function () {
                return null;
            },
            getContent: function (vm, key) {
                /**
                 * Send request to API
                 */
                const path = '/' + this.client.id + '/content/admin/' + key;
                const url = this.$root.app.api_url + path;

                axios({
                    url: url
                })
                .then(response => {
                    response.data.status = 200;
                    vm.get_content_response = response.data;
                })
                .catch(function (error) {
                    vm.get_content_response = {
                        status: error.response.status,
                        error: (error.response.data.error || 'Something went wrong, please try again')
                    }
                });
            },
            getColourOptions: function () {
                if (!this.client) {
                    return [];
                }
                let colours = [
                    {
                        key: 'primary',
                        value: (this.client.primary_colour_name || 'Colour 1'),
                        colour: this.client.primary_colour
                    },
                    {
                        key: 'secondary',
                        value: (this.client.secondary_colour_name || 'Colour 1'),
                        colour: this.client.secondary_colour
                    },
                    {
                        key: 'third',
                        value: (this.client.third_colour_name || 'Colour 1'),
                        colour: this.client.third_colour
                    }
                ];
                if (this.client.additional_colours.length > 0) {
                    this.client.additional_colours.forEach((option) => {
                        colours.push({
                            key: 'colour' + option.id,
                            value: option.colour_name,
                            colour: option.colour
                        });
                    });
                }

                return colours;
            },
            roleName: function (roleId) {
                let itemIndex = -1;
                this.client.client_roles.forEach((item, index) => {
                    if (item.id == roleId) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    return this.client.client_roles[itemIndex].name;
                }

                return 'Select';
            }
        }
    }
</script>
<style scoped>
    .card-header {
        font-weight: 300;
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
    }
</style>
