<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="edit-content-modal-label">{{ title }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="updated" class="alert alert-success" role="alert">
                <i class="far fa-check mr-2"></i>
                <span v-html="updated"></span>
            </div>
            <div v-else>
                <div v-if="loading"><loading></loading></div>

                <div v-if="loading_errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <form v-if="(!loading && !loading_errors)" v-on:submit.prevent="onSubmit()">
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div v-if="!multi_line">
                        <input
                            v-model="content"
                            type="text"
                            id="content"
                            class="form-control"
                            :readonly="updating"
                        >
                    </div>
                    <div v-else>
                        <editor
                            v-if="!updated"
                            :api-key="$root.app.tiny_api_key"
                            v-model="content"
                            :init="tiny_editor_init"
                        ></editor>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="!updated" class="modal-footer">
            <span v-if="!updating">
                <span>
                    <button type="button" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-save mr-1"></i> Save
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Save</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    import Editor from '@tinymce/tinymce-vue';
    export default {
        props: ['client', 'admin_key'],
        components: {
            'editor': Editor
        },
        data () {
            return {
                loading: true,
                loading_errors: false,
                errors: false,
                updating: false,
                updated: false,
                title: 'Edit content',
                key: null,
                content: '',
                multi_line: false,
                mandatory: false,
                get_content_response: null,
                tiny_editor_init: {
                    height: 400,
                    menubar: false,
                    plugins: ['lists', 'code'],
                    toolbar: 'undo redo | cut copy paste pastetext | bullist numlist | bold italic underline '
                        + 'strikethrough | removeformat code'
                },
                company_name: ''
            }
        },
        mounted () {
            this.company_name = this.client.client_name;
            this.$parent.getContent(this, 'company_name');

            // Prevent Bootstrap dialog from blocking focusin
            $(document).on('focusin', function(e) {
                if ($(e.target).closest(".tox-tinymce, .tox-tinymce-aux, .moxman-window, .tam-assetmanager-root").length) {
                    e.stopImmediatePropagation();
                }
            });
        },
        watch: {
            get_content_response: function (val) {
                if (val) {
                    if (val.status == 200) {
                        if ((val.key == 'company_name') && (val.key != this.key)) {
                            this.company_name = val.content;

                            return false;
                        }
                    }
                    if ((val.status == 200) || (val.status == 404)) {
                        this.content = (val.content || this.defaultContent());
                    } else {
                        this.loading_errors = val.error;
                    }
                    this.loading = false;
                    setTimeout(function () {
                        $('#content').focus();
                    }, 200);
                }
            },
        },
        methods: {
            resetForm: function (title, key, multiLine, mandatory = false) {
                this.loading = true;
                this.loading_errors = false;
                this.errors = false;
                this.updating = false;
                this.updated = false;
                this.title = title;
                this.key = key;
                this.content = '';
                this.multi_line = multiLine;
                this.mandatory = mandatory;
                this.get_content_response = null;
                this.$parent.getContent(this, key);
            },
            onSubmit: function () {
                if (this.mandatory && !this.content) {
                    this.errors = 'This is a mandatory field. Please enter a content below.';

                    return false;
                }
                this.errors = false;
                this.updating = true;

                /**
                 * Send update content request to API
                 */
                var path = '/' + this.client.id + '/content/' + this.key + '/admin/' + this.admin_key;
                var url = this.$root.app.api_url + path;
                var data = {
                    content: this.content
                };
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = 'Content updated.';
                    vm.$parent.contents[vm.key] = vm.content;
                    if (vm.key == 'company_name') {
                        vm.company_name = vm.content;
                    }
                    setTimeout(function () {
                        $('#edit-content-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#edit-content-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.status == 404) {
                        /**
                         * Content does not exists - create new
                         * Send create content request to API
                         */
                        path = '/' + vm.client.id + '/content/admin/' + vm.admin_key;
                        url = vm.$root.app.api_url + path;
                        data = {
                            key: vm.key,
                            content: vm.content
                        };

                        axios({
                            method: 'post',
                            url: url,
                            data: data,
                            headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                        })
                        .then(response => {
                            vm.updated = 'Content updated.';
                            vm.$parent.contents[vm.key] = vm.content;
                            if (vm.key == 'company_name') {
                                vm.company_name = vm.content;
                            }
                            setTimeout(function () {
                                $('#edit-content-modal').modal('hide');
                            }, 2000);
                        })
                        .catch(function (error) {
                            if (error.response.status == 401) {
                                // missing/lost API token - logout user
                                $('#edit-content-modal').modal('hide');
                                vm.$root.doLogout();
                            }
                            // show error
                            if (error.response.data.error) {
                                vm.errors = error.response.data.error;
                            } else {
                                vm.errors = 'Something went wrong, please try again';
                            }
                            vm.updating = false;
                        });
                    } else {
                        // show error
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        } else {
                            vm.errors = 'Something went wrong, please try again';
                        }
                        vm.updating = false;
                    }
                });
            },
            defaultContent: function () {
                if (this.key == 'company_name') {
                    return this.client.client_name;
                }
                if (this.key == 'company_contact_name_label') {
                    const companyName = (this.company_name || this.client.client_name);
                    return 'If you are not a ' + companyName + ' employee please complete these fields.<br><br>'
                        + 'Your contact at ' + companyName;
                }
                if (this.key == 'asset_title') {
                    return 'resource';
                }
                if (this.key == 'template_title') {
                    return 'template';
                }
                if (this.key == 'description_title') {
                    return 'description';
                }
                if (this.key == 'profile_group_title') {
                    return 'profile group';
                }
                if (this.key == 'profile_groups_title') {
                    return 'profile groups';
                }
                if (this.key == 'profile_title') {
                    return 'profile';
                }
                if (this.key == 'profiles_title') {
                    return 'profiles';
                }
                if (this.key == 'profile_group_description_title') {
                    return 'site';
                }
                if (this.key == 'financial_approver_title') {
                    return 'financial approver';
                }

                return '';
            }
        }
    }
</script>
